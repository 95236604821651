<template>
  <div :style="{backgroundColor:toobarBackground}" class="toobar-search" :class="{ 'user-toobar-expand': UserIconExpand }">
    <div class="toobar-search-start">
      <div :style="{display:HideSave}" class="save" >
        <img src="@/assets/img/065-bookmark.svg" alt="" >
      </div>
      <div @click="toggleUser" :style="{display:HideUser,backgroundColor:HideUserBg}" class="user" >
        <img :src="HideUserIcon" alt="" >
      </div>
      <div :style="{display:HideHome,backgroundColor:HideHomeBg}" class="home">
          <router-link to="/">
            <img src="@/assets/img/053-home.svg" alt="" >
          </router-link>
      </div>
      <div @click="expandButtonsCompany" :class="{'expandBtnCompany':expandCompany}" :style="{display:HideComapay}" class="company">
        <router-link :to="{ name: route.parceiro }">
          <img src="@/assets/img/company.svg" alt="" />
          <p class="txt-dk">Sou empresa</p>
          <div class="wrapper-links-mobile">
            <p class="Title">Empresa</p>
            <!--<p class="dk-hide">Como posso contratar um profissional disponível no RISE?</p>-->
          </div>
        </router-link>
      </div>
      <div @click="expandButtonsEmployee" :class="{'expandBtnEmployee':expandEmployee}" :style="{display:HideEmployee}" class="employee">
        <!-- <router-link :to="{ name: route.profissional }"> -->
        <a :to="{ name: route.profissional }">
          <img src="@/assets/img/employee2.svg" alt="" />
          <p class="txt-dk">Sou Profissional</p>
           <div class="wrapper-pro-mobile">
            <p class="Title">Profissional</p>
            <!--<p class="dk-hide">Como posso contratar um Profissional disponível no RISE?</p>-->
          </div>
        </a>
      </div>
      <div @click="expandButtonsInput" class="input-wrapper" :style="{display:HideInputSearch}" :class="{'input-wrapper-dark':UserIconExpand, 'expandBtnInput':expandInput}">
        <img  @click="search" src="@/assets/img/search.svg" alt="" />
        <input @keyup="getFun" id="input_search" :style="{backgroundColor:InputBackground}" type="text" :placeholder="placeholderInput" name="search" />
      </div>
    </div>
    <div :class="{'toobar-search-end':UserIconExpand}" class="hide">
        <ul>
          <li @click="removeSession" class="last">
            <p>Terminar Sessão</p>
          </li>
        </ul>
    </div>
    <sign-in-out-modal v-if="confirmModal" @close="confirmModal = false"/>
  </div>
</template>

<script>
import router from '@/router/'
import SignInOutModal from '@/components shared/Modal/ConfirmModalSignOut'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
export default {
  name: 'ToobarSearch',
  components: {
    'sign-in-out-modal': SignInOutModal
  },
  data () {
    return {
      UserIconExpand: false,
      expandCompany: false,
      expandEmployee: false,
      expandInput: false,
      confirmModal: false,
      allSearchs: [],
      route: {
        parceiro: '',
        profissional: ''
      }
    }
  },
  mounted () {
    const ScreenSize = window.screen.width
    if (ScreenSize > 560) {
    }
  },
  created () {
    if (isAuthenticated()) {
      this.route.profissional = 'Home'
    } else if (!isAuthenticated()) {
      this.route.profissional = 'Login'
    } else if (isAuthenticatedParceiro()) {
      this.route.parceiro = 'Home'
    } else if (!isAuthenticatedParceiro()) {
      this.route.parceiro = 'LoginParceiro'
    }
  },
  methods: {
    expandButtonsCompany () {
      if (!isAuthenticatedParceiro() && isAuthenticated()) {
        this.confirmModal = true
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.$router.push({ name: 'LoginParceiro' })
      } else if (isAuthenticatedParceiro() && !isAuthenticated()) {
        this.$router.push({ name: 'MyAreaParceiro' })
      }
    },
    expandButtonsInput () {
      const ScreenSize = window.screen.width
      if (ScreenSize < 560) {
        this.expandInput = true
      }
    },
    expandButtonsEmployee () {
      if (!isAuthenticated() && isAuthenticatedParceiro()) {
        this.confirmModal = true
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.$router.push({ name: 'Login' })
      } else if (!isAuthenticatedParceiro() && isAuthenticated()) {
        this.$router.push({ name: 'MyAreaProfissional' })
      }
      const ScreenSize = window.screen.width
      this.expandInput = false
      if (ScreenSize < 560) {
        this.expandEmployee = true
        this.route.profissional = 'Form'
      }
    },
    getFun () {
      const inputSearch = document.getElementById('input_search')
      if (inputSearch.value.length !== 0) {
        inputSearch.addEventListener('keyup', function (event) {
          if (event.keyCode === 13) {
            event.preventDefault()
            localStorage.setItem('@Rise_Search', inputSearch.value)
            router.push({ name: 'Search' })
          }
        })
      } else {
        return 0
      }
    },
    search () {
      const inputSearch = document.getElementById('input_search')
      if (inputSearch.value.length !== 0) {
        localStorage.setItem('@Rise_Search', inputSearch.value)
        router.push({ name: 'Search' })
      } else {
        return 0
      }
    },
    toggleUser () {
      this.UserIconExpand = !this.UserIconExpand
    },
    removeSession () {
      if (isAuthenticated() || isAuthenticatedParceiro()) {
        this.confirmModal = true
      }
    }
  },
  props: {
    HideEmployee: {
      type: String,
      default: () => 'none'
    },
    HideUser: {
      type: String,
      default: () => 'none'
    },
    HideInputSearch: {
      type: String,
      default: () => 'flex'
    },
    placeholderInput: {
      type: String,
      default: () => 'Pesquise por função, sector ou experiência o Profissional que está à procura'
    },
    HideUserIcon: {
      type: String,
      default: () => require('@/assets/img/097-user.svg')
    },
    HideUserBg: {
      type: String,
      default: () => '#0F7276'
    },
    HideHomeBg: {
      type: String,
      default: () => '#2EA9AD'
    },
    HideComapay: {
      type: String,
      default: () => 'none'
    },
    HideHome: {
      type: String,
      default: () => 'none'
    },
    HideSave: {
      type: String,
      default: () => 'none'
    },
    toobarBackground: {
      type: String,
      default: () => '#0F7276'
    },
    InputBackground: {
      type: String,
      default: () => '#FACA63'
    }
  }
}
</script>

<style lang="scss" scoped>
    @media(min-width:768px){
      .wrapper-pro-mobile,
      .wrapper-links-mobile{
        display: none;
      }
    }
    @media(max-width:768px){
      .txt-dk{
        display: none!important;
      }
      .wrapper-pro-mobile,
      .wrapper-links-mobile{
        display: flex;
        width: 100%!important;
        flex-direction: column;
        .Title{
          font-weight: bold;
          line-height: 16px;
          font-size: 18px!important;
          font-weight: lighter!important;
        }
        .dk-hide{
          max-width: 200px;
          line-height: 16px;
          font-size: 12px!important;
          color: #ffffff90;
        }
      }
    }
    .expandBtnEmployee,
    .expandBtnInput,
    .expandBtnCompany{
      width: 100%!important;
      position: absolute!important;
      z-index: 999;
    }
    .hide{
      display: none;
    }
    .user-toobar-expand{
      // height: 210px!important;
      height: 130px!important;
      // background:#0F7276!important;
    }
    .input-wrapper-dark{
      display: none!important;
    }
    .toobar-search-start{
      display: flex;
      height: 70px;
      width: 100%;
      @media(max-width:768px){
        height: auto;
      }
    }
    .toobar-search-end{
      display: flex;
      padding: 10px 70px 0 70px;
      width: 100%;
      ul{
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        .last{
             border-bottom: unset;
        }
        li{
            cursor: pointer;
            text-align: start;
            border-bottom: 1px solid #ffffff33;
            padding: 10px 0 7px 0;
          p{
            text-decoration: none;
            color: #ffffff88!important;
            font-family: "NexaLight",sans-serif;
          }
        }
      }
    }
    .toobar-search{
      display: flex;
      height: 70px;
      width: 100%;
      bottom: 0;
      flex-direction: column;
      background:#FACA63;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @media(max-width: 768px){
        border-radius: 0!important;
        height: auto!important;
      }
      .employee:hover{
        width:192px ;
        p{
          visibility: visible;
          color: #fff;
          font-size: 12px;
          text-align: start;
          padding-left: 15px;
          display: initial;
        }
        img{
          width: 25px;
          height: auto;
          margin-left: -2px;
        }
      }
      .employee{
        cursor: pointer;
        width: 70px;
        min-width: 70px;
        height: 70px;
        background: #342041;
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 0 ;
        @media(max-width:768px){
          flex: 1!important;
          //width: unset!important;
          height: 80px!important;
          padding: 0!important;
          img{
            width: 40px!important;
          }
        }
        a{
          text-decoration: none;
          padding: 0;
          display: flex;
          align-items: center;
        }
        p{
          visibility: hidden;
          display: none;
        }
        img{
          width: 25px;
          height: auto;
          // margin-left: 25px;
        }
      }
      .company:hover{
          width:192px ;
        p{
          visibility: visible;
          color: #fff;
          font-size: 12px;
          text-align: start;
          padding-left: 15px;
          display: initial;
        }
        img{
          width: 25px;
          height: auto;
          margin-left: -2px;
        }
      }
      .company{
        cursor: pointer;
        border-top-left-radius: 10px;
        width: 70px;
        min-width: 70px;
        height: 70px;
        background: #26A0A5;
        display:flex;
        align-items: center;
        justify-content: center;
        @media(max-width:768px){
          border-radius: 0!important;
          flex: 1;
          height: 80px!important;
          img{
            width: 35px!important;
          }
        }
        a{
          text-decoration: none;
          padding: 0;
          display: flex;
          align-items: center;
        }
          p{
          visibility: hidden;
          display: none;
        }
        img{
          width: 25px;
          height: auto;
        }
      }
      .save{
        cursor: pointer;
        border-top-left-radius: 10px;
        width: 70px;
        min-width: 70px;
        height: 70px;
        background: #26A0A5;
        display:flex;
        align-items: center;
        justify-content: center;
        a{
          text-decoration: none;
          padding: 0;
          display: flex;
          align-items: center;
        }
        p{
          visibility: hidden;
          display: none;
        }
        img{
          width: 25px;
          height: auto;
        }
      }
      .user{
        cursor: pointer;
        border-top-left-radius: 10px;
        width: 70px;
        min-width: 70px;
        height: 70px;
        display:flex;
        align-items: center;
        justify-content: center;
        img{
          width: 25px;
          height: auto;
        }
      }
      .home{
          cursor: pointer;
          width: 70px;
          min-width: 70px;
          height: 70px;
          display:flex;
          align-items: center;
          justify-content: center;
          img{
            width: 25px;
            height: auto;
            margin-bottom: -4px;
          }
      }
      .input-wrapper{
         height: 70px;
         display: flex;
         align-items: center;
         width: 100%;
         @media(max-width:768px){
           height: 80px!important;
           //width: unset!important;
           flex: 1!important;
           input{
             height: 80px!important;
           }
           img{
             width: 32px!important;
           }
         }
         input::placeholder{
            color: #ffffffdd;
            font-weight: lighter;
            @media(max-width:650px){
              color:#FACA63 !important;
            }
          }
          input:focus{
            outline: none;
          }
          input:hover{
            outline: none;
          }
          input{
            width: 100%;
            background: transparent;
            border: none;
            height: 70px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: lighter;
            font-family: "NexaLight", sans-serif;
            color: #fff;
            padding: 10px 70px 10px 40px;
            @media(max-width:568px){
              border-top-right-radius: 0!important;
            }
            &::placeholder{
              color: #ffffff88;
            }
          }
          img{
            cursor: pointer;
            width: 20px;
            height: auto;
            position: absolute;
            transition: width ease-in-out 0.3s;
            right: 12%;
            &:hover{
              width: 25px;
            }
         }
      }
    }
</style>
