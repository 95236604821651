<template>
  <div class="confirmation-modal">
    <transition name="modal">
      <div class="modal-container">
        <div class="modal-wrapper">
          <button v-if="ShowCancelBtn" class="modal-btn-close" @click="$emit('close')">X</button>
          <h3 class="modal-title">{{ModalTitle}}</h3>
          <button v-if="route.parceiro && ShowCancelYes" @click="SignOutParceiro" class="btn-sign-out">Sim</button>
          <button v-if="route.profissional && ShowCancelYes && beTrue" @click="SignOutProfissional" class="btn-sign-out">Sim</button>
          <button v-if="ModalBtnContinue && ShowCancelYes" @click="SignOutParceiro2" class="btn-sign-out">Sim</button>
          <button v-if="ModalBtnContinueProfissional && ShowCancelYes" @click="SignOutParceiro3" class="btn-sign-out">Sim</button>
          <button v-if="ModalGoHome" @click="GoHome" class="btn-sign-out">Ir para página inicial</button>
          <button v-if="ShowCancelBtn" @click="$emit('close')" class="cancel">Cancelar</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
export default {
  name: 'CandidatesModal',
  data () {
    return {
      route: {
        parceiro: false,
        profissional: false
      }
    }
  },
  props: {
    ModalTitle: {
      type: String,
      default: () => 'Tem certeza que pretende terminar a sessão actual?'
    },
    ModalBtnContinue: {
      type: Boolean,
      default: () => false
    },
    ModalBtnContinueProfissional: {
      type: Boolean,
      default: () => false
    },
    ModalGoHome: {
      type: Boolean,
      default: () => false
    },
    ShowCancelBtn: {
      type: Boolean,
      default: () => true
    },
    beTrue: {
      type: Boolean,
      default: () => true
    },
    ShowCancelYes: {
      type: Boolean,
      default: () => true
    }
  },
  created () {
    if (isAuthenticated()) {
      this.route.profissional = true
    } else if (isAuthenticatedParceiro()) {
      this.route.parceiro = true
    }
  },
  methods: {
    SignOutParceiro () {
      sessionStorage.removeItem('@rise_token_parceiro')
      sessionStorage.removeItem('@rise_token_parceiro_created')
      sessionStorage.removeItem('@rise_token_parceiro_expiration')
      sessionStorage.removeItem('@rise_token_profissional')
      sessionStorage.removeItem('@rise_token_profissional_created')
      sessionStorage.removeItem('@rise_token_profissional_expiration')
      this.$router.push({ name: 'LoginParceiro' })
    },
    SignOutParceiro2 () {
      this.SignOutParceiro()
      localStorage.setItem('@Rise_Parceiro_Continue_Activite', 'yes')
    },
    SignOutParceiro3 () {
      this.SignOutParceiro()
      localStorage.setItem('@Rise_Parceiro_Continue_Activite2', 'yes')
    },
    SignOutProfissional () {
      sessionStorage.removeItem('@rise_token_parceiro')
      sessionStorage.removeItem('@rise_token_parceiro_created')
      sessionStorage.removeItem('@rise_token_parceiro_expiration')
      sessionStorage.removeItem('@rise_token_profissional')
      sessionStorage.removeItem('@rise_token_profissional_created')
      sessionStorage.removeItem('@rise_token_profissional_expiration')
      this.$router.push({ name: 'Login' })
    },
    GoHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .confirmation-modal{
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .modal-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #34204122 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
    @media(max-width:768px){
      backdrop-filter: blur(11px);
      -webkit-backdrop-filter: blur(11px);
    }
  }
  .moadal-title{
    max-width: 250px;
  }
  .cancel{
    background-color: #fff!important;
    border: #551948 1px solid!important;
    color: #551948!important;
    margin-top: 20px!important;
  }
  .cancel,
  .btn-sign-out{
    border: none;
    padding: 10px;
    width: 250px;
    margin-top: 30px;
    background-color: #342041;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;
    &:hover{
      background-color: #551948;
    }
  }
  .modal-wrapper {
    max-width:1131px ;
    width: 400px;
    max-height: 704px;
    height: 400px;
    background: #f2f2f2dc;
    display: flex;
    box-shadow: 6px 6px 15px #00000015;
    border-radius: 22px;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    @media(max-width:768px){
      margin-top: 10vh;
      width: 80%;
      height: 80vh;
    }
    .modal-btn-close{
      position: absolute;
      top: 15px;
      right: 15px;
      width: 43px;
      height: 43px;
      font-weight: bold;
      font-size: 16px;
      z-index: 777;
      -webkit-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      box-shadow: 0px -1px 12px -1px rgba(0,0,0,0.16);
      border: none;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
      @media(max-width:768px){
        top: -60px;
        right:0px;
      }
    }
  }
</style>
