<template>
  <figure class="personal-avatar">
    <img
    :src="avatar"
    class="avatar"
    width="100"
    height="120"
    loading="lazy"
    alt />
    <img
    v-if="tipoRise"
    :src="tipoRise"
    class="kind-rise"
    width="40"
    loading="lazy"
    alt="selo Rise"
    />
  </figure>
</template>
<script>
export default {
  name: 'user-avatar',
  props: {
    avatar: String,
    idTipoRiser: [String, Number],
    tipoRise: String
  }
}
</script>
<style lang="scss" scoped>
.personal-avatar {
  width: 170px;
  height: 170px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
  }
  .kind-rise {
    position: absolute;
    bottom: 15px;
    right: 34px;
  }
  &.user-big {
    width: 200px;
    height: 200px;
    .avatar {
      width: 140px;
      height: 160px;
    }
  }
  &.user-medium {
    width: 180px;
    height: 145px;
    .avatar {
      width: 140px;
      height: 145px;
    }
    .kind-rise {
      width: 45px;
      height: 45px;
      bottom: 0px;
      right: 10px;
    }
    @media screen and (min-width: 975px) and (max-width: 1440px) {
      width: 130px;
      height: 90px;
      .avatar {
        width: 80px;
        height: 85px;
      }
      .kind-rise {
        width: 30px;
        height: 30px;
      }
    }
    @media screen and (min-width: 1440px) and (max-width: 1680px) {
      width: 140px;
      height: 100px;
      .avatar {
        width: 90px;
        height: 95px;
      }
      .kind-rise {
        bottom: -15px;
      }
    }
  }
  &.user-small {
    width: 50px;
    height: 50px;
    @media screen and (min-width: 390px){
    width: 75px;
    height: 75px;
    }
    .avatar {
      width: 100%;
      height: 100%;
    }
    .kind-rise {
      width: 25px;
      height: 25px;
      @media screen and (min-width: 390px) {
        width: 35px;
        height: 35px;
      }
      bottom: -10px;
      right: -10px;
    }
  }
}
</style>
